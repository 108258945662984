.life-game > header {
    background-color: #282c34;
    border-bottom: 1px solid black;
    box-shadow: 0 1px 5px black;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.life-game button {
    background-color: #FAF9F6;
    border: 1px solid black;
    border-radius: 0px;
    margin: 5px;
    padding: 2px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .75rem;
    box-shadow: 0 1px 2px black;
}

.life-game button:hover {
    background-color: rgba(250, 249, 246, 0.5);
    border: 1px solid #ff4000;
}

.life-game button svg {
    height: 2.5vh;
    width: 2.5vh;
}

.life-board {
    z-index: -1;
    display: grid;
    grid-template-columns: repeat(150, .8vw);
    grid-template-rows: repeat(150, .8vw);
    column-gap: 1px;
    row-gap: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 800px) {
    .life-board {
        grid-template-columns: repeat(150, .8vh);
        grid-template-rows: repeat(150, .8vh);
    }
}

.life-tile {
    font-size: .3rem;
    border-radius: 2px;
    text-align: center;
    color: dimgray;
    user-select: none;
}

.life-tile:hover {
    background-color: rgba(255, 64, 0, 0.25);
    border: 1px solid #ff4000;
}

.life-tile.alive {
    color: #282c34;
    background-color: #ff4000;
    border: 1px solid #FAF9F6;
}

.life-tile.alive.prime-energy {
    color: #282c34;
    background-color: #2fff00;
    border: 1px solid #FAF9F6;
}
