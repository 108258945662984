.HomePage {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HomePage .header {
    font-size: 3rem;
}

.HomePage .separator {
    height: 3px;
    margin: 10px;
    border: 1px solid black;
    width: 100%;
    background-color: #ff4000;
}

.HomePage .link {
    margin: 10px;
}